import React from "react";
import "./CareerOpportunities.css"; // Custom CSS for styling

function CareerOpportunities() {
  const leaders = [
    {
      name: "Sri. M Bhikamchand Jain",
      title: "Managing Trustee",
      img: `${process.env.PUBLIC_URL}/img/Managing-Trustee.jpg`,
    },
    {
      name: "Sri. P Kishore Kumar Jain",
      title: "Director",
      img: `${process.env.PUBLIC_URL}/img/srmjvteam/Director.jpg`,
    },
    {
      name: "Dr. J Ajeeth Prasath Jain",
      title: "Academic Advisor",
      img: `${process.env.PUBLIC_URL}/img/srmjvteam/Acad.jpg`,
    },
    {
      name: "Smt. Jayashree Narayanan",
      title: "Principal – CBSE",
      img: `${process.env.PUBLIC_URL}/img/srmjvteam/cbseprinci.jpg`,
    },
    {
      name: "Smt. Sujithra Ravinder",
      title: "Principal – Matriculation",
      img: `${process.env.PUBLIC_URL}/img/srmjvteam/mat-princi.jpg`,
    },
  ];

  return (
    <div className="career-container">
      {/* Header Section */}
      <header className="career-header">
        <h1 className="career-title">Career Opportunities</h1>
      </header>

      {/* Introduction Section */}
      <section className="career-intro">
        <h2>Join Our Team at SRMJV</h2>
        <p>
          We believe that teachers and educators are the pillars of academic excellence. At SRMJV,
          we are committed to fostering a professional environment where innovation, passion, and
          dedication thrive. Join us and be a part of our journey to empower the next generation of leaders.
        </p>
      </section>

      {/* Leaders Section */}
      <section className="career-leaders">
        <h2>Meet Our Leadership Team</h2>
        <div className="leaders-row">
          {leaders.map((leader, index) => (
            <div key={index} className="leader-card">
              <img src={leader.img} alt={leader.name} className="leader-photo" />
              <h3 className="leader-name">{leader.name}</h3>
              <p className="leader-title">{leader.title}</p>
            </div>
          ))}
        </div>
      </section>

      {/* Application Section */}
      <section className="career-apply">
        <h2>Apply Now</h2>
        <p>
          If you’re interested in joining our vibrant team, please fill out the application form using the link below:
        </p>
        <a
          href="https://forms.gle/DfkFRkG3aM86rKbU6"
          target="_blank"
          rel="noopener noreferrer"
          className="career-form-link"
        >
          Fill Application Form
        </a>
      </section>
    </div>
  );
}

export default CareerOpportunities;
