import React from "react";
import "./OurJourneySection.css"; // Import custom CSS for styling

function OurJourneySection() {
  return (
    <div className="journey-section">
      {/* Header Section */}
      <header className="header">
        <h1 className="header-title">Our Journey</h1>
        <div className="header-icons">
          {/* Add educational icons here (use images or SVGs as needed) */}
          🎓 📖 ✏️ 🌟 🧑‍🏫
        </div>
      </header>

      {/* Journey Content */}
      <p className="journey-description">
        Sri R.M. Jain Vidhyashram began as a thought in 1994 and was officially inaugurated on 1st April 1996. 
        SRMJV proudly holds the honor of being the first CBSE school in Thiruvallur, offering innovative teaching methodologies, 
        steady progression, and integrating spirituality in education.
      </p>
      <p className="journey-description">
        With a student strength of 3100, the school stands as a co-educational institution affiliated with CBSE, New Delhi, 
        and has English as its medium of instruction. Our aim is to mold children into efficient and eminent citizens, providing a holistic education system.
      </p>
      <p className="journey-description">
        We believe in candor and veracity, inspiring learners to achieve success and render selfless service to humanity. 
        Join us in nurturing the future leaders of the world with a blend of academic and ethical excellence.
      </p>

      {/* Images */}
      <div className="journey-images">
        <img
          src={`${process.env.PUBLIC_URL}/img/school-interior.jpg`}
          alt="School Interior"
          className="journey-image"
        />
        <img
          src={`${process.env.PUBLIC_URL}/img/school-building.jpg`}
          alt="School Building"
          className="journey-image"
        />
        <img
          src={`${process.env.PUBLIC_URL}/img/Tiny-Tots.JPG`}
          alt="Tiny Tots Block"
          className="journey-image"
        />
      </div>
    </div>
  );
}

export default OurJourneySection;