import React from "react";
import { Swiper, SwiperSlide } from "swiper/react"; // Import Swiper and SwiperSlide
import "swiper/css"; // Import Swiper styles
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "./LibraryClubSection.css"; // Custom CSS for styling
import { Autoplay, EffectCoverflow, Pagination } from "swiper/modules"; // Swiper modules

function LibraryClubSection() {
  const photos = [
    `${process.env.PUBLIC_URL}/img/lib/photo1.jpg`,
    `${process.env.PUBLIC_URL}/img/lib/photo2.jpg`,
    `${process.env.PUBLIC_URL}/img/lib/photo3.jpg`,
    `${process.env.PUBLIC_URL}/img/lib/photo4.jpg`,
    `${process.env.PUBLIC_URL}/img/lib/photo5.jpg`,
    `${process.env.PUBLIC_URL}/img/lib/photo6.jpg`,
  ];

  return (
    <div className="library-clubs-section">
      {/* Text Content */}
      <div className="library-content">
        <h2 className="library-club-title">Empowering Minds with SRMJV Library & Clubs</h2>
        <p className="library-description">
          SRMJV provides a spacious and well-equipped library combined with digital content to help children excel in learning and understanding concepts.
        </p>
        <p className="library-description">
          The school supports various clubs and academies, including:
          <ul className="library-list">
            <li>Story Teller Club</li>
            <li>Science Club</li>
            <li>Literary Club</li>
            <li>Fine-Arts Academy</li>
            <li>Cricket Academy</li>
            <li>Badminton Academy</li>
            <li>Karuna Club</li>
            <li>Eco Club</li>
          </ul>
        </p>
        <p className="library-description">
          At SRMJV, we practice the ethics of love and compassion towards all while committing to the holistic development of our students.
        </p>
      </div>

      {/* Swiper Photo Gallery */}
      <div className="library-gallery">
        <Swiper
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          slidesPerView={"auto"}
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }}
          pagination={{ clickable: true }}
          autoplay={{
            delay: 2500, // Auto-swipe every 2.5 seconds
            disableOnInteraction: false, // Auto-swipe even when user interacts
          }}

          modules={[EffectCoverflow, Pagination, Autoplay]}
          className="swiper-gallery"
        >
          {photos.map((photo, index) => (
            <SwiperSlide key={index} className="swiper-slide">
              <img src={photo} alt={`Photo ${index + 1}`} className="swiper-image" />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

export default LibraryClubSection;