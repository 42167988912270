import React from "react";
import "./TinyTots.css"; // Custom CSS for styling

function TinyTotsBlock() {
  return (
    <div className="tiny-tots-section">
      <div className="tiny-tots-content">
        <h2 className="tiny-tots-title">The Inauguration of Tiny Tots Block</h2>
        <p className="tiny-tots-description">
          At SRMJV, curricular and co-curricular activities are given equal importance. Children are encouraged to perform to their fullest capacity by providing thorough training in the fields they choose. 
        </p>
        <p className="tiny-tots-description">
          The dynamic educational program and the unique blend of curriculum enable an easy transition of students from school to tertiary education anywhere in the world.
        </p>
      </div>

      <div className="tiny-tots-image">
        <img
          src={`${process.env.PUBLIC_URL}/img/tiny-tots.JPG`} // Ensure image is saved in the `public/img` folder
          alt="Tiny Tots Block Inauguration"
          className="tiny-tots-image-element"
        />
      </div>
    </div>
  );
}

export default TinyTotsBlock;