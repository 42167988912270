import React from "react";
import "./OurPurpose.css"; // Include the custom CSS for styling

function OurPurpose() {
  return (
    <div className="our-purpose-container">
      {/* Header Section */}
      <header className="header">
        <h1 className="header-title">Our Purpose</h1>
        <div className="header-icons">
          {/* Add educational icons here (use images or SVGs as needed) */}
          🎓 📖 ✏️ 🌟 🧑‍🏫
        </div>
      </header>

      {/* Content Section */}
      <section className="content">
        {/* Logo and Mission Statement */}
        <div className="left-section">
          <div className="logo">
            <img
              src={`${process.env.PUBLIC_URL}/img/purlogo.png`}
              alt="Sri R.M. Jain Group of Schools Logo"
              className="school-logo"
              style={{ maxWidth: "150px", margin: "20px auto", display: "block" }}
            />
          </div>
          <div className="mission-statement">
            <p>
              We are a learning community dedicated to inspiring success and nurturing well-being. 
              We believe that everyone deserves the best possible future. We guide our students to 
              realize their individual academic potential.
            </p>
            <p>
              The best way to help our children is to work with their teachers. Help them with their 
              homework, get to know their strengths and weaknesses. The success of the children we 
              care for and educate is reliant on our ability to work together.
            </p>
          </div>
        </div>

        {/* Teaching Triangle */}
        <div className="right-section">
          
          <img
            src={`${process.env.PUBLIC_URL}/img/Teaching_Triangle1.jpg`}
            alt="Teaching Triangle"
            style={{ maxWidth: "300px", margin: "20px auto", display: "block" }}
          />
          <p className="triangle-description">
            A collaborative relationship between teachers, students, and parents to foster growth 
            and success.
          </p>
        </div>
    </section>
    
    <section>
      {/* Right Section: Collaborative Diagrams */}
      <div className="bottom-section">
        <h2>Let’s ensure that Parents and Teachers join together in raising the children.</h2>

        {/* First Diagram: Student Triangle of Support */}
        <div className="diagram1">
          <img
            src={`${process.env.PUBLIC_URL}/img/31.jpg`}
            alt="Student Triangle of Support"
            className="diagram-image"
          />
        
          <img 
            src={`${process.env.PUBLIC_URL}/img/32.png`}
            alt="Teaching Triangle"
            className="diagram-image"
          />
        </div>
      </div>
    </section>
  </div>


  );
}

export default OurPurpose;
