import React from "react";
import "./Header.css";

function Header() {
  return (
    <header className="header">
      
     {/* Main Navbar */}
           <nav className="navbar navbar-expand-lg navbar-light bg-light">
             <div className="container-fluid">
               {/* School Brand Section */}
               <a className="navbar-brand" href="/">
                 <img
                   src={`${process.env.PUBLIC_URL}/img/logo.png`}
                   alt="Sri R.M. Jain Group of Schools Logo"
                   className="school-logo"
                 />
                 <span>Sri R.M.JAIN GROUP OF SCHOOLS</span>
               </a>
     
               {/* Toggler Button for Mobile View */}
               <button
                 className="navbar-toggler"
                 type="button"
                 data-bs-toggle="collapse"
                 data-bs-target="#navbarNavDropdown"
                 aria-controls="navbarNavDropdown"
                 aria-expanded="false"
                 aria-label="Toggle navigation"
               >
                 <span className="navbar-toggler-icon"></span>
               </button>
     
               {/* Navbar Links */}
               <div className="collapse navbar-collapse" id="navbarNavDropdown">
                 <ul className="navbar-nav ms-auto">
                   <li className="nav-item">
                     <a className="nav-link" href="/">
                       Home
                     </a>
                   </li>
     
                   {/* About Us Dropdown */}
                   <li className="nav-item dropdown">
                     <a
                       className="nav-link dropdown-toggle"
                       href="#"
                       id="aboutDropdown"
                       role="button"
                       data-bs-toggle="dropdown"
                       aria-expanded="false"
                     >
                       About Us
                     </a>
                     <ul className="dropdown-menu" aria-labelledby="aboutDropdown">
                       <li><a className="dropdown-item" href="/vidhyapeeth/about-us/history">History of the School</a></li>
                       <hr className="dropdown-seperator" />
                       <li><a className="dropdown-item" href="/vidhyapeeth/about-us/smdc">SMDC</a></li>
                       <hr className="dropdown-seperator" />
                       <li><a className="dropdown-item" href="/vidhyapeeth/about-us/pta">PTA</a></li>
                       <hr className="dropdown-seperator" />
                       <li><a className="dropdown-item" href="/vidhyapeeth/about-us/sexual-harassment-committee">Sexual Harassment Committee</a></li>
                       <hr className="dropdown-seperator" />
                       <li><a className="dropdown-item" href="/vidhyapeeth/about-us/student-care-cell">Student CareCell</a></li>
                       
                       <hr className="dropdown-seperator" />
                       <li><a className="dropdown-item" href="/vidhyapeeth/about-us/grievance-redressal-committee">Grievance Redressal Committee</a></li>
                     </ul>
                   </li>
     
                   {/* Facilities Dropdown */}
                   <li className="nav-item dropdown">
                     <a
                       className="nav-link dropdown-toggle"
                       href="#"
                       id="facilitiesDropdown"
                       role="button"
                       data-bs-toggle="dropdown"
                       aria-expanded="false"
                     >
                       Facilities
                     </a>
                     <ul className="dropdown-menu" aria-labelledby="facilitiesDropdown">
                       <li><a className="dropdown-item" href="/vidhyapeeth/facilities/Infrastructure">Infrastructure</a></li>
                       <hr className="dropdown-seperator" />
     
                       {/* Dropdown for Transport */}
           <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="transportDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Online Fees
              </a>
              <ul className="dropdown-menu drop-top" aria-labelledby="transportDropdown">
                <li><a className="dropdown-item" href="https://easyerponline.in/rmjaincbse/school/" target="_blank">Vidhyashram Online Fees</a></li>
                <hr className="dropdown-divider" />
                <li><a className="dropdown-item" href="https://easyerponline.in/rmjainmatric/school/" target="_blank">Vidhyapeeth Online Fees</a></li>
                
              </ul>
            </li>
                       <li><a className="dropdown-item" href="/vidhyapeeth/facilities/Library">Library</a></li>
                       <hr className="dropdown-seperator" />
     
                       <li><a className="dropdown-item" href="/vidhyapeeth/facilities/Lab">Lab</a></li>
                       <hr className="dropdown-seperator" />
     
                       <li><a className="dropdown-item" href="/vidhyapeeth/facilities/badminton">Badminton Court</a></li>
                       <hr className="dropdown-seperator" />
     
                       <li><a className="dropdown-item" href="/vidhyapeeth/facilities/smartboard">Smart Boards</a></li>
                       <hr className="dropdown-seperator" />
     
     
                       <li><a className="dropdown-item" href="/vidhyapeeth/facilities/schoolground">School Ground</a></li>
                       <hr className="dropdown-seperator" />
     
                       <li><a className="dropdown-item" href="/vidhyapeeth/facilities/transport">Tranport</a></li>
                     </ul>
                   </li>
     
                   {/* Social & Community Services Dropdown */}
                   <li className="nav-item dropdown">
                     <a
                       className="nav-link dropdown-toggle"
                       href="#"
                       id="communityDropdown"
                       role="button"
                       data-bs-toggle="dropdown"
                       aria-expanded="false"
                     >
                       Social & Community Services
                     </a>
                     <ul className="dropdown-menu" aria-labelledby="communityDropdown">
                       <li><a className="dropdown-item" href="/vidhyapeeth/community-services/scouts">Scoutes & Guides</a></li>
                       <hr className="dropdown-seperator" />
                       <li><a className="dropdown-item" href="/vidhyapeeth/community-services/karuna">Karuna Club</a></li>
                       <hr className="dropdown-seperator" />
                       <li><a className="dropdown-item" href="/vidhyapeeth/community-services/Eco">Eco Club</a></li>
                     </ul>
                   </li>
     
                   {/* Other Links */}
                   <li className="nav-item">
                     <a className="nav-link" href="/vidhyapeeth/rules-regulations">Rules & Regulations</a>
                   </li>
                   <li className="nav-item">
                     <a className="nav-link" href="/vidhyapeeth/curriculum">Curriculum</a>
                   </li>
                   <li className="nav-item">
                     <a className="nav-link" href="/vidhyapeeth/Achievements">Achievements</a>
                   </li>
                   <li className="nav-item">
                     <a className="nav-link" href="/vidhyapeeth/gallery">Gallery</a>
                   </li>
                   
                 </ul>
               </div>
             </div>
           </nav>
    </header>
  );
}

export default Header;