import React from "react";
import "../index.css";

function Header() {
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container">
        {/* Brand Logo and Name */}
        <a className="navbar-brand d-flex align-items-center" href="/">
          <img
            src={`${process.env.PUBLIC_URL}/img/logo.png`}
            alt="Brand Logo"
            width="110" // Set width
            height="80" // Same height for a square
            className="me-3" // Adds spacing between logo and text
            style={{ borderRadius: "10%" }} // Optional: Rounded corners for a softer look
          />
          <div style={{ textAlign: "center", color: "#0047AB" }}>
            <span style={{ fontSize: "1.2rem", fontWeight: "bold", display: "block" }}>
              Sri R.M. JAIN
            </span>
            <span style={{ fontSize: "1rem", fontWeight: "bold", display: "block" }}>
              GROUP OF SCHOOLS
            </span>
          </div>
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav">
            {/* Dropdown for About Us */}
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="aboutDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                About Us
              </a>
              <ul className="dropdown-menu drop-top" aria-labelledby="aboutDropdown">
                <li><a className="dropdown-item" href="/our-purpose">Our Purpose</a></li>
                <hr className="dropdown-divider" />
                <li><a className="dropdown-item" href="/wearethankful">We Are Thankful</a></li>
                <hr className="dropdown-divider" />
                <li><a className="dropdown-item" href="/Administration">Administration</a></li>
                <hr className="dropdown-divider" />
                <li><a className="dropdown-item" href="/team">SRMJVTeam</a></li>
              </ul>
            </li>
            {/* Dropdown for Our Schools */}
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="schoolsDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Our Schools
              </a>
              <ul className="dropdown-menu drop-top" aria-labelledby="schoolsDropdown">
                <li><a className="dropdown-item" href="/vidhyashram">Sri R.M. Jain Vidhyashram Senior Secondary School(CBSE)</a></li>
                <hr className="dropdown-divider" />
                <li><a className="dropdown-item" href="/vidhyapeeth">Sri R.M. Jain Vidhyapeeth Mat. Hr. Secondary School(Matric)</a></li>
                
              </ul>
            </li>
            {/* Dropdown for Transport */}
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="transportDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Transport
              </a>
              <ul className="dropdown-menu drop-top" aria-labelledby="transportDropdown">
                <li><a className="dropdown-item" href="/transportrules">Rules & Regulations</a></li>
                <hr className="dropdown-divider" />
                <li><a className="dropdown-item" href="/Bus-Routes">Bus Routes</a></li>
                
              </ul>
            </li>

            <li className="nav-item">
              <a className="nav-link" href="/training">Teachers Training</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/careers">Career Opportunities</a>
            </li>
           {/* Dropdown for Transport */}
           <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="transportDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Online Fees
              </a>
              <ul className="dropdown-menu drop-top" aria-labelledby="transportDropdown">
                <li><a className="dropdown-item" href="https://easyerponline.in/rmjaincbse/school/" target="_blank">Vidhyashram Online Fees</a></li>
                <hr className="dropdown-divider" />
                <li><a className="dropdown-item" href="https://easyerponline.in/rmjainmatric/school/" target="_blank">Vidhyapeeth Online Fees</a></li>
                
              </ul>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="https://forms.gle/DsHy5AAbcAnLf5ig8" target="_blank">Alumni</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/Contactus">Contact Us</a>
            </li>
            {/* Scholarship Test Dropdown */}
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="scholarshipDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Scholarship Test 2025
              </a>
              <ul
                className="dropdown-menu drop-top"
                aria-labelledby="scholarshipDropdown"
              >
                <li>
                  <a
                    className="dropdown-item"
                    href="https://forms.gle/XY6pTxjfTdPynxmF7"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Apply Here
                  </a>
                </li>
                <hr className="dropdown-divider" />
                <li>
                  <a
                    className="dropdown-item"
                    href={`${process.env.PUBLIC_URL}/img/Guideline & Instruction.pdf`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Guidelines & Instructions
                  </a>
                </li>
                <hr className="dropdown-divider" />
                <li>
                  <a
                    className="dropdown-item"
                    href={`${process.env.PUBLIC_URL}/img/Sample Question Paper for Scholarship Test 2025.pdf`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Sample Question Paper
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Header;