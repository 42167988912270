import React from "react";
import "./Header.css"; // Custom CSS for styling
import "bootstrap/dist/css/bootstrap.min.css"; // Bootstrap CSS
import "bootstrap/dist/js/bootstrap.bundle.min.js"; // Bootstrap JavaScript functionality

function Header() {
  return (
    <header className="header-container">
      {/* Top Bar Section */}
      <div className="top-bar">
        <div className="container d-flex justify-content-end">
          <a href="/mandatory-disclosure" className="top-link">
            Mandatory Public Disclosure
          </a>
          <a href="/pay-fees" className="top-link">
            Pay Fees
          </a>
          <a href="/admission" className="top-link">
            Admission
          </a>
        </div>
      </div>

      {/* Main Navbar */}
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          {/* School Brand Section */}
          <a className="navbar-brand" href="/">
            <img
              src={`${process.env.PUBLIC_URL}/img/logo.png`}
              alt="Sri R.M. Jain Group of Schools Logo"
              className="school-logo"
            />
            <span>Sri R.M.JAIN GROUP OF SCHOOLS</span>
          </a>

          {/* Toggler Button for Mobile View */}
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          {/* Navbar Links */}
          <div className="collapse navbar-collapse" id="navbarNavDropdown">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                <a className="nav-link" href="/">
                  Home
                </a>
              </li>

              {/* About Us Dropdown */}
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="aboutDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  About Us
                </a>
                <ul className="dropdown-menu" aria-labelledby="aboutDropdown">
                  <li><a className="dropdown-item" href="/vidhyashram/about-us/ourjourney">Our Journey</a></li>
                  <hr className="dropdown-seperator" />
                  <li><a className="dropdown-item" href="/vidhyashram/about-us/our-objectives">Our Objectives</a></li>
                  <hr className="dropdown-seperator" />
                  <li><a className="dropdown-item" href="/vidhyashram/about-us/sexual-harassment-committee">Sexual Harassment Committee</a></li>
                  <hr className="dropdown-seperator" />
                  <li><a className="dropdown-item" href="/vidhyashram/about-us/student-care-cell">Student CareCell</a></li>
                  
                  <hr className="dropdown-seperator" />
                  <li><a className="dropdown-item" href="/vidhyashram/about-us/grievance-redressal-committee">Grievance Redressal Committee</a></li>
                </ul>
              </li>

              {/* Facilities Dropdown */}
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="facilitiesDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Facilities
                </a>
                <ul className="dropdown-menu" aria-labelledby="facilitiesDropdown">
                  <li><a className="dropdown-item" href="/vidhyashram/facilities/Infrastructure">Infrastructure</a></li>
                  <hr className="dropdown-seperator" />

                  
                  <li><a className="dropdown-item" href="/vidhyashram/facilities/Library">Library</a></li>
                  <hr className="dropdown-seperator" />

                  <li><a className="dropdown-item" href="/vidhyashram/facilities/Lab">Lab</a></li>
                  <hr className="dropdown-seperator" />

                  <li><a className="dropdown-item" href="/vidhyashram/facilities/badminton">Badminton Court</a></li>
                  <hr className="dropdown-seperator" />

                  <li><a className="dropdown-item" href="/vidhyashram/facilities/smartboard">Smart Boards</a></li>
                  <hr className="dropdown-seperator" />


                  <li><a className="dropdown-item" href="/vidhyashram/facilities/schoolground">School Ground</a></li>
                  <hr className="dropdown-seperator" />

                  <li><a className="dropdown-item" href="/vidhyashram/facilities/transport">Tranport</a></li>
                </ul>
              </li>

              {/* Social & Community Services Dropdown */}
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  id="communityDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Social & Community Services
                </a>
                <ul className="dropdown-menu" aria-labelledby="communityDropdown">
                  <li><a className="dropdown-item" href="/vidhyashram/community-services/scouts">Scoutes & Guides</a></li>
                  <hr className="dropdown-seperator" />
                  <li><a className="dropdown-item" href="/vidhyashram/community-services/karuna">Karuna Club</a></li>
                  <hr className="dropdown-seperator" />
                  <li><a className="dropdown-item" href="/vidhyashram/community-services/Eco">Eco Club</a></li>
                </ul>
              </li>

                {/* Dropdown for Transport */}
           <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="transportDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Online Fees
              </a>
              <ul className="dropdown-menu drop-top" aria-labelledby="transportDropdown">
                <li><a className="dropdown-item" href="https://easyerponline.in/rmjaincbse/school/" target="_blank">Vidhyashram Online Fees</a></li>
                <hr className="dropdown-divider" />
                <li><a className="dropdown-item" href="https://easyerponline.in/rmjainmatric/school/" target="_blank">Vidhyapeeth Online Fees</a></li>
                
              </ul>
            </li>
              {/* Other Links */}
              <li className="nav-item">
                <a className="nav-link" href="/vidhyashram/rules-regulations">Rules & Regulations</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/vidhyashram/curriculum">Curriculum</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/vidhyashram/Achievements">Achievements</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/vidhyashram/gallery">Gallery</a>
              </li>
              
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;